import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Search } from '../components/search-by-att';

function BlogTemplate({ data, pageContext }) {
  return (
    <Search att="Category" data={data} pageContext={pageContext} showAskLawyerCard />
  );
}

export const query = graphql`
query ($limit: Int, $skip: Int) {
  wpPage(uri: {eq: "/blog/"}) {
    ...SeoPage
    title
    uri
  }
  allAtts: allWpCategory {
    nodes {
      uri
      name
      databaseId
      wpParent {
        node {
          name
          wpParent {
            node {
              name
            }
          }
        }
      }
    }
  }
  posts: allWpPost(
    sort: {fields: date, order: DESC}
    limit: $limit
    skip: $skip
  ) {
    nodes {
      title
      date
      schemaMarkup {
        eaPostSchemaUploadDate
        eaPostSchemaVideoName
      }
      excerpt
      uri
      featuredImage {
        node {
          gatsbyImage(width:300)
        }
      }
    }
  }
}
`;

BlogTemplate.propTypes = {
  data: object,
  pageContext: object,
};

BlogTemplate.defaultProps = {
  data: {},
  pageContext: {},
};

export default BlogTemplate;
export { Head } from '../components/seo/seo';
